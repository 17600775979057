import { NavSidebar, ThemeConfig, initEnviroment } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import MeasuringPointLobby from "./pages/FrontiersCgmLobby";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default hot(function Root() {
  return (
    <ThemeConfig>
      <MeasuringPointLobby />
    </ThemeConfig>
  );
});
