import { PathOptions } from "../models";

export const pathOptions: PathOptions[] = [
  {
    icon: "SignalCellular",
    text: "Consulta y detalle",
    path: "#/reading-records",
    footerText: "Registro de consumos y reportes a XM",
  },
  {
    icon: "AutoStoriesIcon",
    text: "Consulta y detalle",
    path: "#/admins-directory",
    footerText: "Directorio de administradores",
  },
  {
    icon: "FlareIcon",
    text: "Consulta y detalle",
    path: "#/fustigations",
    footerText: "Fustigaciones",
  },
];
