import { styled, Typography, ArrowForwardIcon, Box } from "@enerbit/base";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import FlareIcon from "@mui/icons-material/Flare";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BuildIcon from "@mui/icons-material/Build";

import * as singleSpa from "single-spa";
import { PathOptions } from "../models";

interface Props {
  card: PathOptions;
}

const CardPath = ({ card }: Props): JSX.Element => {
  const { text, icon, path, footerText } = card;

  const iconOptions: Record<string, any> = {
    SignalCellular: <SignalCellularAltIcon color='primary' sx={{ fontSize: "35px" }} />,
    FlareIcon: <FlareIcon color='primary' sx={{ fontSize: "35px" }} />,
    AutoStoriesIcon: <AutoStoriesIcon color='primary' sx={{ fontSize: "35px" }} />,
    BuildIcon: <BuildIcon color='primary' sx={{ fontSize: "35px" }} />,
  };

  return (
    <Card onClick={() => singleSpa.navigateToUrl(`${path}`)} id={path.replace("#/", "")}>
      <ItemContainer>{iconOptions[icon]}</ItemContainer>
      <ItemContainer sx={{ height: "33%" }}>
        <Typography color='primary'>{text}</Typography>
      </ItemContainer>
      <CardFooter>
        <AlingItem>
          <Typography color='white' sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {footerText}
          </Typography>
          <Box sx={{ marginRight: "-23px" }}>
            <Circle heigth='92px' num='3'>
              <Circle heigth='59px' num='4'>
                <Circle heigth='30px' num='6'>
                  <ArrowForwardIcon sx={{ fontSize: "20px", fontWeight: "bold" }} />
                </Circle>
              </Circle>
            </Circle>
          </Box>
        </AlingItem>
      </CardFooter>
    </Card>
  );
};

export default CardPath;

const Card = styled("div")`
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 197px;
  outline: 1px solid #53358e;
  width: 100%;
`;

type CircleProps = {
  heigth: String;
  num: String;
};

const Circle = styled("div")(({ heigth, num }: CircleProps) => ({
  alignItems: "center",
  width: `${heigth}`,
  height: `${heigth}`,
  display: "flex",
  justifyContent: "center",
  backgroundColor: `rgb(128, 105, 171, 0.${num})`,
  borderRadius: "50%",
}));

const ItemContainer = styled("div")`
  height: 33.33%;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const AlingItem = styled("div")`
  align-items: center;
  display: flex;
  color: white;
  height: 100%;
  justify-content: space-between;
  padding: 0 0 0 20px;
`;

const CardFooter = styled("div")`
  background-color: #53358e;
  height: 70px;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
`;
